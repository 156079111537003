html {
  font-size: 62.5%;

  &.sr .load-hidden {
    visibility: hidden;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  scroll-behavior: smooth;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: $light-grey;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.cursor-canvas {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}